import hazbotLogo from 'assets/img/team/40x40/Hazball.png';

import { FileAttachment } from 'components/common/AttachmentPreview';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFaceSmile,
  faPenToSquare,
  faReply,
  faShare,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

import { v4 as uuidv4 } from 'uuid';

export interface Message {
  id: number;
  type: 'sent' | 'received';
  message?: string;
  time: string;
  readAt: Date | string | null;
  attachments?: { images?: string[]; file?: FileAttachment };
  seen?: boolean;
}
export interface User {
  id: number;
  avatar?: string;
  status: 'online' | 'offline';
  name: string;
}
export interface Conversation {
  id: string;
  user: User;
  messages: Message[];
  unreadMessages?: number;
}

export type MessageActionType = {
  icon: IconProp;
  label: string;
};

export const chatBotInstance: Conversation = {
  id: uuidv4(),
  user: { id: 1, avatar: hazbotLogo, status: 'online', name: 'TestBot' },
  messages: []
};

export const suggestions: string[] = [
  'What kind of support do you offer?',
  'information on Hazmat Regulations',
  'I need to contain a chemical leak',  
];

/*
export const suggestions: string[] = [
  'What is the amount of sulfuric acid I can load in a cylindrical tank, considering a 22% vapor expansion chamber (cut)? Please provide the amount of product in gallons, metric tons, and US pounds.',
  'What are the U.S. regulations for the handling, transport, and storage of sodium cyanide? I would like information on key regulations, such as those issued by the Department of Transportation (DOT) under 49 CFR and the Environmental Protection Agency (EPA), etc. Also, provide information regarding the classification, labeling, and transportation of the product, as well as the recommended chemical and respiratory protection for cleaning up a 3-ton spill of sodium cyanide pellets inside a warehouse.',
  'What chemical reaction occurs when ethyl bromide and hydrochloric acid are mixed, and what are the consequences and products of this reaction? I would like to know the risks associated with this mixture, such as the possible release of toxic or corrosive gases, as well as the recommended safety measures for handling these compounds. Please provide an analysis of the physicochemical consequences of the reaction and a stoichiometric calculation.',
]; */

export const files: FileAttachment[] = [
  {
    name: 'Federico_salsaniuella_godarf_design.zip',
    size: '53.34 MB',
    date: 'Dec 8, 2011',
    format: 'zip'
  },
  {
    name: 'Restart_lyf.bat',
    size: '11.13 KB',
    date: 'Dec 2, 2011',
    format: 'bat'
  },
  {
    name: 'Fake lorem ipsum fr fr.txt',
    size: '11.13 KB',
    date: 'Dec 2, 2011',
    format: 'txt'
  },
  {
    name: 'Unsupported file format.mad',
    size: '11.13 KB',
    date: 'Dec 2, 2011',
    format: 'mad'
  }
];

export const actions: MessageActionType[] = [
  {
    icon: faTrash,
    label: 'Delete'
  },
  {
    icon: faReply,
    label: 'Reply'
  },
  {
    icon: faPenToSquare,
    label: 'Edit'
  },
  {
    icon: faShare,
    label: 'Share'
  },
  {
    icon: faFaceSmile,
    label: 'Emoji'
  }
];
