import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import Badge from 'components/base/Badge';
import Button from 'components/base/Button';
import { Card } from 'react-bootstrap';
import {
  PricingAlternate,
  pricingFeaturesAlternate
} from 'data/landing/pricingData';
import { useCart } from 'components/CartContext';
import { Link } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import tester from 'assets/img/first.png';

export interface CartItem {
  category: string;
  price: number;
  quantity: number;
}

interface PricingItemProps {
  pricing: PricingAlternate;
  isLocked: boolean;
  hasActiveSelection: boolean;
}

const PricingItem = ({ pricing, isLocked, hasActiveSelection }: PricingItemProps) => {
  const { cart, addToCart, removeFromCart } = useCart();

  const [stockAvailable, setStockAvailable] = useState(pricing.initialStock);
  const itemsInCart = cart.filter(item => item.category === pricing.category)
    .reduce((total, item) => total + (item.quantity || 1), 0);

  const handleAddToCart = () => {
    if (stockAvailable > 0) {
      const existingCartItem = cart.find(item => item.category === pricing.category);
      
      if (existingCartItem) {
        addToCart({
          category: pricing.category,
          price: pricing.price,
          quantity: (existingCartItem.quantity || 1) + 1
        });
      } else {
        addToCart({
          category: pricing.category,
          price: pricing.price,
          quantity: 1
        });
      }
      setStockAvailable((prev) => prev - 1);
    }
  };

  const handleRemoveFromCart = () => {
    if (itemsInCart > 0) {
      removeFromCart(pricing.category);
      setStockAvailable((prev) => prev + itemsInCart);
    }
  };

  const isDisabled = hasActiveSelection && cart.length > 0 && !cart.some(item => item.category === pricing.category);
  const isSelected = cart.some(item => item.category === pricing.category);
  
  return (
    <div className={classNames('pricing-card position-relative', {
      'opacity-25': isDisabled
    })}>
      <div className="position-absolute top-0 end-0 mt-3 me-3 z-1">
        <Button
          variant="light"
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={{ width: '40px', height: '40px' }}
        >
          <div className="position-relative">
            <Link to="/cart" className="position-relative">
              <FontAwesomeIcon icon={faShoppingCart} className="text-primary" />
              {itemsInCart > 0 && (
                <Badge
                  variant="phoenix"
                  bg="danger"
                  className="position-absolute top-0 start-100 translate-middle rounded-pill"
                  style={{ fontSize: '0.65rem', padding: '0.25rem 0.4rem' }}
                >
                  {itemsInCart}
                </Badge>
              )}
            </Link>
          </div>
        </Button>
      </div>
      <Card
        className={classNames('bg-transparent', {
          'border border-2 border-success border-gradient success rounded-4': isSelected,
          'border border-2 border-info rounded-4': !isSelected && pricing.category === 'Premium Plan',
          'border-0 border-translucent': !isSelected && pricing.category !== 'Premium Plan'
        })}
      >
        <Card.Body className="p-7">
          <h3 className="mb-5">{pricing.category}</h3>
          {pricing.category === 'Basic Plan' 
            ? <div className='position-absolute' style={{right:0,textAlign:'right'}}><img width='200' src={tester}></img></div>
            : null
          }
          <h1 className="fs-3 d-flex align-items-center gap-1 mb-3">
            ${pricing.price}
            <span className="fs-8 fw-normal"> / month</span>
          </h1>

          <div className="text-muted mb-3">
            Available Stock: {stockAvailable}
          </div>

          <Button
            variant={pricing.category === 'Premium' ? 'primary' : 'outline-primary'}
            size="lg"
            className="w-100 mb-3"
            onClick={handleAddToCart}
            disabled={stockAvailable === 0 || isDisabled}
          >
            {stockAvailable > 0
              ? (isDisabled
                ? 'Select Other First'
                : `Add to Cart${itemsInCart > 0 ? ' (' + itemsInCart + ')' : ''}`)
              : 'No Stock'}
          </Button>

          <div className="mb-7">
            <Button
              variant="outline-danger"
              size="lg"
              className="w-100"
              onClick={handleRemoveFromCart}
              disabled={itemsInCart === 0}
            >
              Remove from Cart {itemsInCart > 0 ? `(${itemsInCart})` : ''}
            </Button>
          </div>

          <h5 className="mb-4">What's included</h5>
          <ul className="fa-ul ps-4 m-0 pricing">
  {pricingFeaturesAlternate.map((feature, index) => (
    <li
      key={feature.id}
      className={classNames('d-flex align-items-center', {
        'mb-3': index !== pricingFeaturesAlternate.length - 1,
        'text-muted': !pricing.features.includes(feature.id), // Grey out non-included features
        'opacity-50': !pricing.features.includes(feature.id) // Optional: apply reduced opacity for non-included features
      })}
    >
      {pricing.features.includes(feature.id) && (
        <span className="fa-li">
          <FontAwesomeIcon
            icon={feature.icon}
            className="text-primary"
          />
        </span>
      )}
      <p className="mb-0">{feature.label}</p>
      {feature.new && pricing.category === 'Basic Plan' && (
        <Badge variant="phoenix" bg="info" className="ms-2 fs-10">
          New
        </Badge>
      )}
    </li>
  ))}
</ul>

        </Card.Body>
      </Card>

      {isDisabled && (
        <div className="position-absolute top-0 start-0 w-100 h-100 bg-gray-400 opacity-25 rounded-4" 
             style={{ pointerEvents: 'none' }} />
      )}
    </div>
  );
};

interface PricingGridProps {
  pricingOptions: PricingAlternate[];
}

const PricingGrid = ({ pricingOptions }: PricingGridProps) => {
  const { cart } = useCart();
  const hasActiveSelection = cart.length > 0;

  return (
    <div className="relative">
      <div className="row g-3">
        {pricingOptions.map((pricing) => (
          <div key={pricing.category} className="col-12 col-lg-4">
            <PricingItem
              pricing={pricing}
              isLocked={cart.length > 0 && !cart.some(item => item.category === pricing.category)}
              hasActiveSelection={hasActiveSelection}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export { PricingGrid };
export default PricingItem;