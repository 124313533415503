import { Card } from 'react-bootstrap';
import chatIllustration from 'assets/img/convo.png';
import chatDarkIllustration from 'assets/img/convo.png';
import Scrollbar from 'components/base/Scrollbar';

const ChatHomepageCard = () => {
  return (
    <Card className="h-100 w-100 d-none d-sm-block">
      <Scrollbar>
        <Card.Body className="h-100 d-flex flex-column flex-center text-center">
          <img
            src={chatIllustration}
            alt="chat"
            height={'100%'} //260
            width={'100%'} //320 - mb-15
            className="mb-3 d-dark-none"
          />
          <img
            src={chatDarkIllustration}
            alt="chat"
            height={'100%'}
            width={'100%'}
            className="mb-3 d-light-none"
          />
          <h3 className="text-body fw-semibold mb-3 fs-7 fs-sm-6">
            Click to select a Conversation
          </h3>
        </Card.Body>
      </Scrollbar>
    </Card>
  );
};

export default ChatHomepageCard;
