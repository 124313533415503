import bg from 'assets/img/bg/bg-40.png';
import bgDark from 'assets/img/bg/bg-dark-40.png';
import { Link } from 'react-router-dom';
import SearchBox from 'components/common/SearchBox';
import { Button, Col, Modal, Row, Tab } from 'react-bootstrap';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import CategoryTab from 'components/modules/faq/CategoryTab';
import SubCategoryTab from 'components/modules/faq/SubCategoryTab';
import SubCategoryContent from 'components/modules/faq/SubCategoryContent';
import FaqTabProvider, { useFaqTabContext } from 'providers/FaqTabProvider';
import CategoryOffcanvas from 'components/modules/faq/CategoryOffcanvas';
import { useState } from 'react';

const index = () => {
  return (
    <FaqTabProvider>
      <FaqTab />
    </FaqTabProvider>
  );
};

const FaqTab = () => {
  const { breakpoints } = useBreakpoints();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showCookiesModal, setShowCookiesModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalMessage, setSuccessModalMessage] = useState('');

  const {
    activeKey,
    setActiveKey,
    subCategoryActiveKey,
    setSubCategoryActiveKey
  } = useFaqTabContext();

  return (
    <div className="mb-9">
      <div
        className="mx-n4 mx-lg-n6 mt-n5 position-relative mb-md-9"
        style={{ height: '208px' }}
      >
        <div
          className="bg-holder d-dark-none"
          style={{
            backgroundImage: `url(${bg})`,
            backgroundSize: 'cover'
          }}
        />
        <div
          className="bg-holder d-light-none"
          style={{
            backgroundImage: `url(${bgDark})`,
            backgroundSize: 'cover'
          }}
        />
        <div className="faq-title-box position-relative bg-body-emphasis border border-translucent p-6 rounded-3 text-center mx-auto">
          <h1>How can we help?</h1>
          <p className="my-3">
            Welcome to Hazbot Help Center. Here you will find information about Hazbot, If you need further assistance; please contact our
            <Link to="mailto:support@creatimus.com"> Support Team</Link> 
            <div className="my-2 text-center fw-bold fs-10 text-body-quaternary">
              <span
                className="text-body-quaternary me-1 cursor-pointer"
                onClick={() => setShowTermsModal(true)}
              >
                Terms
              </span>
              •
              <span
                className="text-body-quaternary mx-1 cursor-pointer"
                onClick={() => setShowPrivacyModal(true)}
              >
                Privacy
              </span>
              •
              <span
                className="text-body-quaternary ms-1 cursor-pointer"
                onClick={() => setShowCookiesModal(true)}
              >
                Cookies
              </span>
            </div>
            {/* Terms Modal */}
      <Modal show={showTermsModal} onHide={() => setShowTermsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terms of Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Effective Date: November 1st, 2024</h3>
          <h3>Last Updated: October 24th, 2024</h3>
          
<br></br>
<p>
Welcome to Hazbot. Please read these Terms and Conditions (“Terms”) carefully before using our website or services. By accessing or using Hazbot’s website or software, you agree to be bound by these Terms. If you do not agree, please discontinue use immediately.
</p>

<h4>1. About Hazbot and Ownership</h4>
<p>
Hazbot and its associated website are products of Creatimus LLC, headquartered in Houston, Texas. Hazbot’s technology infrastructure and AI services are developed in partnership with Linuxcorp USA. Linuxcorp Latam serves as the exclusive representative of Creatimus for Hazbot in Chile and Latin America.
</p>

<h4>2. Eligibility and User Responsibility</h4>
<ol>
<li>Users must be at least 18 years old or have the consent of a legal guardian.</li>
<li>Users agree to use Hazbot only for lawful purposes related to chemical risk analysis, hazard management, or other intended functionalities.</li>
<li>Users are responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account.</li>
</ol>

<h4>3. Advisory Nature of Hazbot’s Services</h4>
<p>
Hazbot is an advisory tool only and is not intended to replace the advice or judgment of certified professionals. All final decisions, actions, or inactions taken by users are their sole responsibility.
</p>
<ul>
<li>Users must consult certified experts and professionals before using Hazbot’s recommendations in real emergencies or critical situations.</li>
<li>Hazbot provides guidance based on its algorithms and data but does not guarantee accuracy, completeness, or suitability for any specific scenario.</li>
</ul>

<h4>4. Intellectual Property Rights</h4>
<ol>
<li>All content, code, data, and materials on Hazbot’s platform, including logos, trademarks, software, and documentation, are the property of Creatimus LLC and its partners.</li>
<li>Users are granted a limited, non-exclusive, non-transferable license to access and use Hazbot solely for personal or business purposes. Unauthorized copying, modification, or distribution is strictly prohibited.</li>
</ol>

<h4>5. Acceptable Use Policy</h4>
<ol>
<li>Users agree not to:
<ul>
<li>Violate any applicable laws or regulations.</li>
<li>Use Hazbot’s services for unauthorized purposes or malicious activities.</li>
<li>Interfere with the functionality or security of the website or any connected networks.</li>
</ul>
</li>
<li>Creatimus LLC reserves the right to suspend or terminate accounts that engage in prohibited activities.</li>
</ol>

<h4>6. Disclaimer of Warranties</h4>
<p>
Hazbot’s services are provided "as-is" without warranties of any kind, express or implied. The platform’s recommendations should not be used as a substitute for professional judgment in real-life emergencies.
</p>
<ul>
<li>Users assume all risks related to the application of Hazbot’s advice.</li>
<li>Creatimus LLC and its partners do not guarantee that Hazbot’s information is accurate, up-to-date, or applicable to every situation.</li>
</ul>

<h4>7. Limitation of Liability</h4>
<ol>
<li>Creatimus LLC, Linuxcorp USA, and Linuxcorp Latam are not liable for any damages resulting from decisions, actions, or outcomes based on Hazbot’s advice or data.</li>
<li>Users acknowledge that all responsibility for decisions rests with them, and the use of Hazbot’s recommendations is at their own risk.</li>
<li>Hazbot does not provide emergency response services or crisis management. Users must always consult with appropriate experts or emergency responders before making decisions.</li>
</ol>

<h4>8. Third-Party Services and Links</h4>
<p>
Hazbot’s platform may contain links to third-party websites or services. Creatimus LLC does not endorse or assume responsibility for these external resources and shall not be held liable for any interactions users have with them.
</p>

<h4>9. Privacy Policy</h4>
<p>
All data collected from users is governed by our Privacy Policy, which explains how we collect, store, and protect personal data. By using Hazbot, users agree to the terms of the Privacy Policy.
</p>

<h4>10. User Data and Confidentiality</h4>
<ol>
<li>Hazbot processes and stores information to provide its services. While we strive to secure data, we cannot guarantee the security of transmitted data.</li>
<li>Users must notify us immediately if they suspect any unauthorized access to their account.</li>
</ol>

<h4>11. Modification of Terms</h4>
<p>
Creatimus LLC reserves the right to modify or update these Terms at any time. We will notify users of significant changes through the website or via email. Continued use of the services after changes constitutes acceptance of the revised Terms.
</p>

<h4>12. Termination</h4>
<p>
Creatimus LLC reserves the right to suspend or terminate access to Hazbot services at any time without notice if users violate these Terms or engage in harmful activities.
</p>

<h4>13. Governing Law and Dispute Resolution</h4>
<ol>
<li>These Terms are governed by the laws of the State of Texas, USA, without regard to its conflict of law principles.</li>
<li>Any disputes will be resolved through arbitration in Houston, Texas, unless otherwise required by law.</li>
</ol>

<h4>14. Contact Information</h4>
<p>If you have any questions about these Terms, please contact us at:</p>
<p>
Creatimus LLC<br />
support@creatimus.com
</p>
          {/* Additional content as needed */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowTermsModal(false)}>
            CLose
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Privacy Modal */}
      <Modal show={showPrivacyModal} onHide={() => setShowPrivacyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3>Effective Date: November 1st, 2024</h3>
          <h3>Last Updated: October 24th, 2024</h3>
<br></br>
<p>
This Privacy Policy explains how Creatimus LLC (“we,” “our,” or “us”) collects, uses, and protects your personal information when you use Hazbot and its associated website. By accessing or using Hazbot, you agree to the collection and use of information in accordance with this policy.
</p>

<h4>1. Information We Collect</h4>
<p>We collect the following types of data to provide and improve Hazbot’s services:</p>
<h5>1.1 Personal Information</h5>
<ul>
<li>Name, email address, phone number (if provided for account setup or communication).</li>
<li>Company or organization information (for business accounts).</li>
</ul>

<h5>1.2 Usage Data</h5>
<ul>
<li>IP address, browser type, device information, and access times when visiting the website.</li>
<li>Data regarding interactions with Hazbot's features (e.g., inputs for hazard analysis or search queries).</li>
</ul>

<h5>1.3 Cookies and Tracking Technologies</h5>
<p>
We use cookies and similar technologies to enhance the user experience, track website traffic, and remember user preferences. You can manage your cookie preferences through your browser settings.
</p>

<h4>2. How We Use Your Information</h4>
<p>We use the collected data to:</p>
<ol>
<li>Provide, operate, and improve Hazbot’s services.</li>
<li>Customize the user experience and develop new features.</li>
<li>Communicate with users about updates, promotions, or technical support.</li>
<li>Analyze website traffic and usage patterns to improve performance.</li>
<li>Ensure compliance with legal obligations and enforce our Terms and Conditions.</li>
</ol>

<h4>3. Sharing Your Information</h4>
<p>
We do not sell or rent personal data to third parties. However, we may share your data in the following circumstances:
</p>
<ol>
<li>With Service Providers: Third-party vendors that assist us with hosting, analytics, and support services.</li>
<li>Legal Compliance: If required to do so by law or to respond to valid legal requests (e.g., subpoenas).</li>
<li>Business Transfers: In case of a merger, sale, or transfer of our assets, your data may be transferred to the new entity.</li>
</ol>

<h4>4. Data Retention</h4>
<p>
We retain personal information for as long as necessary to provide services or as required by law. When no longer needed, data is securely deleted or anonymized.
</p>

<h4>5. Your Data Rights</h4>
<p>
Depending on your location, you may have the following rights:
</p>
<ol>
<li>Access and Correction: Request access to the personal data we hold about you or correct inaccuracies.</li>
<li>Data Deletion: Request deletion of your personal data, subject to legal or contractual obligations.</li>
<li>Data Portability: Obtain a copy of your personal data in a portable format.</li>
<li>Withdraw Consent: If you previously gave consent to data processing, you can withdraw it at any time.</li>
</ol>
<p>
To exercise your rights, please contact us at [Insert Email Address].
</p>

<h4>6. Security Measures</h4>
<p>
We implement technical and organizational measures to protect your data from unauthorized access, disclosure, or alteration. However, no system is entirely secure, and we cannot guarantee the absolute security of your information. If you become aware of any security breach, please notify us immediately at [Insert Email Address].
</p>

<h4>7. Children’s Privacy</h4>
<p>
Hazbot is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you believe we have collected data from a child, please contact us, and we will promptly delete the information.
</p>

<h4>8. International Data Transfers</h4>
<p>
If you are accessing Hazbot from outside the United States, please note that your data may be transferred to and processed in the United States, where data protection laws may differ from those in your jurisdiction.
</p>

<h4>9. Third-Party Links and Services</h4>
<p>
Our website may contain links to third-party services or websites. We are not responsible for the privacy practices of these external services. Please review their privacy policies before interacting with them.
</p>

<h4>10. Changes to This Privacy Policy</h4>
<p>
We may update this Privacy Policy periodically. When changes are made, we will notify you by posting the updated policy on our website and updating the "Last Updated" date. Your continued use of Hazbot after changes constitutes your acceptance of the revised policy.
</p>

<h4>11. Contact Us</h4>
<p>
If you have any questions about these Terms, please contact us at:<br />
Creatimus LLC<br />
support@creatimus.com
</p>
          {/* Additional content as needed */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowPrivacyModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cookies Modal */}
      <Modal show={showCookiesModal} onHide={() => setShowCookiesModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cookies Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  <h3>Effective Date: November 1st, 2024</h3>
  <h3>Last Updated: October 24th, 2024</h3>
  <br />
  <p>
    This Cookie Policy explains how Creatimus LLC (“we,” “our,” or “us”) uses cookies and similar technologies on the Hazbot website and its associated services. By accessing or using Hazbot, you consent to the use of cookies in accordance with this policy.
  </p>

  <h4>1. What Are Cookies?</h4>
  <p>
    Cookies are small text files that are stored on your device (computer, tablet, smartphone) when you visit a website. They help enhance your browsing experience by remembering your preferences and enabling certain functionalities.
  </p>

  <h4>2. Types of Cookies We Use</h4>
  <p>We use the following types of cookies:</p>
  <h5>2.1 Essential Cookies</h5>
  <ul>
    <li>These cookies are necessary for the website to function and cannot be turned off. They include, for example, session cookies that allow you to log into secure areas of our website.</li>
  </ul>

  <h5>2.2 Performance Cookies</h5>
  <ul>
    <li>These cookies collect information about how visitors use our website, such as which pages are visited most often. This data helps us improve our website’s performance.</li>
  </ul>

  <h5>2.3 Functional Cookies</h5>
  <ul>
    <li>These cookies allow our website to remember choices you make (such as your username, language, or region) and provide enhanced, more personalized features.</li>
  </ul>

  <h5>2.4 Targeting Cookies</h5>
  <ul>
    <li>These cookies are used to deliver advertisements that are relevant to you and your interests. They are also used to limit the number of times you see an advertisement and help measure the effectiveness of advertising campaigns.</li>
  </ul>

  <h4>3. How We Use Cookies</h4>
  <p>We use cookies to:</p>
  <ol>
    <li>Improve the functionality and usability of Hazbot.</li>
    <li>Analyze website traffic and usage patterns.</li>
    <li>Remember your preferences and settings.</li>
    <li>Deliver personalized content and advertisements.</li>
  </ol>

  <h4>4. Managing Cookies</h4>
  <p>
    You have the right to accept or reject cookies. You can manage your cookie preferences through your browser settings. Most browsers allow you to refuse cookies or delete them. If you choose to refuse cookies, you may not be able to use the full functionality of Hazbot.
  </p>

  <h4>5. Third-Party Cookies</h4>
  <p>
    In addition to our own cookies, we may also use various third-party cookies to report usage statistics of Hazbot and deliver advertisements on and off the website. These third parties may collect information about your online activities over time and across different websites when you use Hazbot.
  </p>

  <h4>6. Changes to This Cookie Policy</h4>
  <p>
    We may update this Cookie Policy from time to time. When changes are made, we will notify you by posting the updated policy on our website and updating the "Last Updated" date. Your continued use of Hazbot after changes constitutes your acceptance of the revised policy.
  </p>

  <h4>7. Contact Us</h4>
  <p>
    If you have any questions about this Cookie Policy, please contact us at:<br />
    Creatimus LLC<br />
    support@creatimus.com
  </p>
</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowCookiesModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
          </p>
          {/*<SearchBox className="w-100" placeholder="" />*/}
        </div>
      </div>
      <Tab.Container
        defaultActiveKey={activeKey}
        onSelect={(key: string | null) => setActiveKey(key || '')}
        activeKey={activeKey}
      >
        <Row className="gx-xl-8 gx-xxl-11">
          {breakpoints.up('md') && <CategoryTab />}
          <Col
            md={6}
            xl={7}
            xxl={8}
            className="empty-header d-none d-md-block"
          />
          <Col xs={12} className="m-0">
            <Tab.Container
              defaultActiveKey={subCategoryActiveKey}
              onSelect={(key: string | null) =>
                setSubCategoryActiveKey(key || '')
              }
              id="sub-category"
              activeKey={subCategoryActiveKey}
            >
              <Row className="gx-xl-8 gx-xxl-11 gy-6">
                {breakpoints.up('md') && <SubCategoryTab />}
                <Col md={6} xl={7} xxl={8} className="mt-0">
                  <SubCategoryContent />
                </Col>
              </Row>
            </Tab.Container>
            {breakpoints.down('md') && <CategoryOffcanvas />}
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default index;
