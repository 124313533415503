/* @v3.0 - modificación burbujas texto y ajustes de contenedores div */
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Avatar from 'components/base/Avatar';
import Lightbox from 'components/base/LightBox';
import { Message as MessageType, User, actions } from 'data/hazbot/chat';
import useLightbox from 'hooks/useLightbox';
import MessageActionButtons from './MessageActionButtons';
import MessageAttachments from './MessageAttachments';
import AttachmentPreview from 'components/common/AttachmentPreview';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { KatexOptions } from 'katex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckDouble, 
  faChevronDown, 
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { Spinner } from 'react-bootstrap';

interface CodeProps {
  inline?: boolean;
  className?: string;
  children: React.ReactNode;
  node?: any;
}

interface PreProps {
  children: React.ReactNode;
}

interface MessageProps {
  message: MessageType;
  user: User;
  showActions?: boolean;
}

const MessageContent: React.FC<{ content: string; isReceived: boolean }> = ({ content, isReceived }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [isAnimating, setIsAnimating] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);

  const processBlockMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\[([\s\S]*?)\\\]/g, (_, math) => `\n$$${math}$$\n`);
  };

  const processInlineMath = (text: string): string => {
    if (!text || typeof text !== 'string') return '';
    return text.replace(/\\\((.*?)\\\)/g, (_, math) => `$${math}$`);
  };

  const katexOptions: KatexOptions = {
    strict: false,
    throwOnError: false,
    displayMode: true,
    trust: true,
  };

  useEffect(() => {
    if (!content || typeof content !== 'string') {
      setDisplayedContent('');
      setIsAnimating(false);
      return;
    }

    if (!isReceived) {
      const processedContent = processInlineMath(processBlockMath(content));
      setDisplayedContent(processedContent);
      setIsAnimating(false);
      return;
    }

    const processedContent = processInlineMath(processBlockMath(content));
    const cleanContent = processedContent.replace(/undefined/g, '');
    const words = cleanContent.split(/(\s+)/).filter(word => 
      word !== undefined && 
      word !== null && 
      word !== 'undefined' && 
      typeof word === 'string'
    );

    let currentIndex = 0;
    let timeoutId: NodeJS.Timeout;

    const animateText = () => {
      if (currentIndex < words.length) {
        const word = words[currentIndex];
        if (word && typeof word === 'string') {
          setDisplayedContent(prev => prev + word);
        }
        currentIndex++;
        timeoutId = setTimeout(animateText, 70);

        if (containerRef.current) {
          const messageContainer = containerRef.current.closest('.message-container');
          if (messageContainer) {
            requestAnimationFrame(() => {
              messageContainer.scrollTop = messageContainer.scrollHeight;
            });
          }
        }
      } else {
        setIsAnimating(false);
      }
    };

    setDisplayedContent('');
    setIsAnimating(true);
    currentIndex = 0;
    animateText();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      setIsAnimating(false);
    };
  }, [content, isReceived]);

  if (!displayedContent && !isAnimating) return null;

  const CustomCode: React.FC<CodeProps> = ({ 
    inline = false, 
    className, 
    children,
    ...props 
  }) => {
    const style = {
      display: 'block',
      maxWidth: '100%',
      overflow: 'auto',
      wordWrap: 'break-word' as const,
      whiteSpace: inline ? 'normal' as const : 'pre-wrap' as const
    };
    return (
      <code className={className} style={style} {...props}>
        {children}
      </code>
    );
  };

  const CustomPre: React.FC<PreProps> = ({ children }) => (
    <pre style={{ 
      maxWidth: '100%', 
      overflow: 'auto',
      marginBottom: '1rem'
    }}>
      {children}
    </pre>
  );

  return (
    <div 
      ref={containerRef} 
      className="prose max-w-none message-scroll-container"
      style={{ 
        display: 'block',
        maxWidth: '100%',
        overflow: 'hidden',
        wordWrap: 'break-word',
        overflowWrap: 'break-word'
      }}
    >
      <ReactMarkdown
        remarkPlugins={[remarkMath]}
        rehypePlugins={[[rehypeKatex, katexOptions]]}
        components={{
          code: CustomCode as any,
          pre: CustomPre as any
        }}
      >
        {displayedContent || ''}
      </ReactMarkdown>
      {isAnimating && isReceived && displayedContent && (
        <div className="d-flex align-items-center gap-2">
          <Spinner animation="border" size="sm" />
        </div>
      )}
    </div>
  );
};

const Message: React.FC<MessageProps> = ({ message, user, showActions = true }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { lightboxProps, openLightbox } = useLightbox(
    message.attachments?.images || []
  );

  const showCollapseButton = message.type === 'received';

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="d-flex chat-message">
      <div
        className={classNames('d-flex flex-1', {
          'justify-content-end': message.type === 'sent'
        })}
      >
        <div
          className={classNames('w-100', {
            'w-xxl-75': showActions
          })}
          style={{ maxWidth: '100%' }}
        >
          {showCollapseButton && (
            <div className="d-flex justify-content-end mb-1">
              <button 
                onClick={toggleAccordion}
                className={classNames(
                  'border-0 bg-transparent p-1 rounded-circle d-flex align-items-center justify-content-center',
                  'hover:bg-light transition-colors',
                  'text-body-tertiary',
                  'cursor-pointer'
                )}
                style={{ 
                  width: '20px', 
                  height: '20px',
                  fontSize: '12px'
                }}
              >
                <FontAwesomeIcon 
                  icon={isExpanded ? faChevronUp : faChevronDown}
                  className="text-gray-500 hover:text-gray-700"
                />
              </button>
            </div>
          )}

          {(!showCollapseButton || isExpanded) && (
            <>
              <div
                className={classNames('d-flex hover-actions-trigger', {
                  'flex-end-center': message.type === 'sent'
                })}
              >
                {message.type === 'received' && (
                  <div className='me-2'> </div>
                )}
                {message.type === 'sent' && showActions && (
                  <MessageActionButtons actions={actions} variant="sent" />
                )}
                <div
                  className={classNames('me-2', {
                    received: message.type === 'received'
                  })}
                  style={{ maxWidth: '100%', flex: '1 1 auto' }}
                >
                  <div
                    className={classNames('mb-1', {
                      'sent-message-content': message.type === 'sent',
                      'received-message-content border border-translucent':
                        message.type === 'received',
                      attachments:
                        Boolean(message.attachments?.images?.length) &&
                        !message.message
                    })}
                    style={{ maxWidth: '100%' }}
                  >
                    {message.message && (
                      <div className="mb-0 message-container" style={{ 
                        maxWidth: '100%',
                        overflow: 'hidden'
                      }}>
                        <MessageContent 
                          content={message.message} 
                          isReceived={message.type === 'received'}
                        />
                      </div>
                    )}
                    {message.attachments?.images && (
                      <MessageAttachments
                        attachments={message.attachments.images}
                        openLightbox={openLightbox}
                      />
                    )}
                    {message.attachments?.file && (
                      <AttachmentPreview
                        attachment={message.attachments.file}
                        variant={
                          message.type === 'received' ? 'primary' : 'secondary'
                        }
                      />
                    )}
                  </div>
                </div>
                {message.type === 'received' && showActions && (
                  <MessageActionButtons
                    actions={actions.slice(1)}
                    variant="received"
                  />
                )}
              </div>
              <div
                className={classNames('d-flex gap-1 fs-10', {
                  'ms-7': message.type === 'received',
                  'justify-content-end': message.type === 'sent'
                })}
              >
                <p className="mb-0 text-body-tertiary text-opacity-85 fw-semibold">
                  {message.time}
                </p>
                {message.readAt && (
                  <FontAwesomeIcon icon={faCheckDouble} className="text-success" />
                )}
              </div>
              <Lightbox {...lightboxProps} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Message;