/* @stats v1.0 */
import React, { useEffect, useState } from 'react';
import {
  Icon,
  UilAtom,
  UilBooks,
  UilBrain,
  UilInvoice,
  UilRefresh,
  UilRobot,
  UilUsersAlt
} from '@iconscout/react-unicons';
import { UserService } from 'service/userService';

export interface DashboardStats {
  title: string;
  count: string;
  icon: Icon;
  iconColor: string;
  subtitle: string;
}

const baseStats: Omit<DashboardStats, 'count'>[] = [
  {
    title: 'Tokens',
    icon: UilAtom,
    iconColor: 'primary',
    subtitle: 'Token Package'
  },
  {
    title: 'NeuroSkills',
    icon: UilBrain,
    iconColor: 'success',
    subtitle: 'NeuroSkills Activated'
  },
  {
    title: 'Prompts',
    icon: UilInvoice,
    iconColor: 'warning',
    subtitle: 'Prompts used'
  },
  {
    title: 'Tokens',
    icon: UilRobot,
    iconColor: 'danger',
    subtitle: 'Your available tokens'
  }
];

export const useDashboardStats = () => {
  const [stats, setStats] = useState<DashboardStats[]>([]);

  useEffect(() => {
    const initializeStats = async () => {
      const baseTokenLimit = await UserService.getChatTokensLimit();
      const remainingTokens = await UserService.getChatTokensRemaining();
      
      const fullStats: DashboardStats[] = [
        {
          ...baseStats[0],
          count: String(baseTokenLimit.toLocaleString('en-US'))
        },
        {
          ...baseStats[1],
          count: '5'
        },
        {
          ...baseStats[2],
          count: '7'
        },
        {
          ...baseStats[3],
          count: String(remainingTokens.toLocaleString('en-US'))
        }
      ];

      setStats(fullStats);
    };

    initializeStats();
  }, []);

  return stats;
};