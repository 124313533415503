//import Badge from 'components/base/Badge';
//import EcomTotalOrdersChart from 'components/charts/e-charts/EcomTotalOrdersChart';
import {Card, Col, Dropdown, Form, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
/*import EcomTotalOrdersCard from 'components/cards/EcomTotalOrdersCard';
import EcomNewCustomersCard from 'components/cards/EcomNewCustomersCard';
import EcomTopCouponsCard from 'components/cards/EcomTopCouponsCard';
import EcomPayingVsNonPayingCard from 'components/cards/EcomPayingVsNonPayingCard';
import EcomLatestReviewsTable from 'components/tables/EcomLatestReviewsTable';
import EcomTopRegionsMap from 'components/leaflet-maps/EcomTopRegionsMap';
import EcomStats from 'components/stats/EcomStats';
import { mapMarkerPoints } from 'data/mapMarkerPoints';
import EcomProjectionVsActual from 'components/modules/e-commerce/dashboard/EcomProjectionVsActual';
import EcomReturningCustomerRate from 'components/modules/e-commerce/dashboard/EcomReturningCustomerRate';
import EcomTotalSells from 'components/modules/e-commerce/dashboard/EcomTotalSells';
import EcomTopRegions from 'components/modules/e-commerce/dashboard/EcomTopRegions'; */

//import IntegratedChatBot from 'chatbot/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import HazbotConversation from 'components/common/chat-widget/HazbotConversation';
import HazbotFooter from 'components/common/chat-widget/HazbotFooter';
import { useAppContext } from 'providers/AppProvider';
import { useChatWidgetContext } from 'providers/ChatWidgetProvider';
import { faCircle, faDiceD20, faDownload, faUpload } from '@fortawesome/free-solid-svg-icons';

const Ecommerce = () => {
  const {
    config: { isChatWidgetVisible }
  } = useAppContext();
  const { isOpenChat, setIsOpenChat } = useChatWidgetContext();
  return (
    <>
        <Row className="g-2 mb-2">
          <Col xs={5} xxl={5} xg={5} xl={5}>
            {/*<Card className="bg-body-emphasis">
              <Card.Header className="d-flex flex-between-center px-4 py-2 border-bottom">*/}
                <h6 className="mb-0 d-flex align-items-center gap-2">Neuroskills v0.0</h6>
              {/*</Card.Header>
              <Card.Body>

              </Card.Body>
            </Card>*/}
          </Col>

          <Col xs={7} xxl={7} xg={7} xl={7}>
            <FontAwesomeIcon icon={faDiceD20} />
            <FontAwesomeIcon icon={faDownload} />
            <FontAwesomeIcon icon={faUpload} />

    {/* <Form.Label>Advanced Skills</Form.Label>
      <ReactSelect
        options={[
          {
            value: 'Calculations',
            label: 'Calculations'
          },
          { value: 'Transport and Storage', label: 'Transport and Storage' },
          { value: 'Compliance and Standards', label: 'Compliance and Standards' },
          {
            value: 'Chemical Properties',
            label: 'Chemical Properties'
          },
          {
            value: 'Equipment Selection',
            label: 'Equipment Selection'
          },
          {
            value: 'Decontamination',
            label: 'Decontamination'
          },
          {
            value: 'Risk Assesment',
            label: 'Risk Assesment'
          },
          {
            value: 'Spill Control',
            label: 'Spill Control'
          },
          {
            value: 'Marine Emergencies',
            label: 'Marine Emergencies'
          },
          {
            value: 'Hazmat Response',
            label: 'Hazmat Response'
          }
        ]}
        isMulti
        placeholder="Select Skill..."
      /> */}
              {/*<Card.Header className="d-flex flex-between-center px-4 py-2 border-bottom">*/}
                <h5 className="mb-0 d-flex align-items-center gap-2">Standard Abilities</h5>
              {/*</Card.Header>*/}
          </Col>
        </Row>

        <Row className="g-1">
          <Col xs={12} xxl={12} xg={12} xl={12}>
            <Card className="bg-body-emphasis chat">
              <Card.Header className="d-flex flex-between-center px-4 py-2 border-bottom">
                <h6 className="mb-0 d-flex align-items-center gap-2">
                  HAZBOT is available
                  <FontAwesomeIcon icon={faCircle} className="text-success fs-11" /> {/* className="text-success/danger fs-11" */}
                </h6>
                {/*<RevealDropdownTrigger>
                <RevealDropdown>
                  <Dropdown.Item>Request a callback</Dropdown.Item>
                  <Dropdown.Item>Search in chat</Dropdown.Item>
                  <Dropdown.Item>Show history</Dropdown.Item>
                  <Dropdown.Item>Report to Admin</Dropdown.Item>
                <Dropdown.Item onClick={() => setIsOpenChat(!isOpenChat)}>
                  Close Support
                </Dropdown.Item>
              </RevealDropdown>
            </RevealDropdownTrigger>*/}
              </Card.Header>
              <Card.Body className="scrollbar p-3">
                <HazbotConversation />
              </Card.Body>
              <Card.Footer className="border-top ps-3 pe-4 py-3">
                < HazbotFooter />
              </Card.Footer>
            </Card>
          </Col>
        </Row>
              {/*<Col xs={12} md={6}>
                <EcomTopCouponsCard />
              </Col>
              <Col xs={12} md={6}>
                <EcomPayingVsNonPayingCard />
              </Col>*/}
      {/*
      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-7 border-y">
        <EcomLatestReviewsTable />
      </div>
      <Row className="gx-6">
        <Col xs={12} xl={6}>
          <EcomTopRegions />
        </Col>
        <Col xs={12} xl={6}>
          <div className="mx-n4 mx-lg-n6 ms-xl-0 h-100">
            <div className="h-100 w-100" style={{ minHeight: 300 }}>
              <EcomTopRegionsMap data={mapMarkerPoints} />
            </div>
          </div>
        </Col>
      </Row>
      <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-body-emphasis pt-6 pb-9 border-top">
        <Row className="g-6">
          <Col xs={12} xl={6}>
            <EcomProjectionVsActual />
          </Col>
          <Col xs={12} xl={6}>
            <EcomReturningCustomerRate />
          </Col>
        </Row>
      </div>*/}
    </>
  );
};

export default Ecommerce;
