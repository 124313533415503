import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faBuilding, faLock } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/base/Button';
import { Col, Form, Row, Modal, Card } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiUrl, currencyFormat, getHatateToken, getUserIp, hasSession } from "helpers/utils";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Section from 'components/base/Section';
import { useCart } from 'components/CartContext';
import PrivacyModal from 'components/modals/global/PrivacyModal';
import TermsModal from 'components/modals/global/TermsModal';
import ErrorModal from 'components/modals/global/ErrorModal';
import SuccessModal from 'components/modals/global/SuccessModal';
import { IRegistration } from 'data/models/user/IUser';
import { UserService } from 'service/userService';
import { IMarketPaymentCart } from 'data/models/payment/IPayment';
import { PaymentService } from 'service/paymentService';
import { useUserContext } from 'layouts/UserContext';

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const formIconStyles = `
.form-icon-container {
  position: relative;
}
.form-icon-input {
  padding-left: 35px;
}
.form-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-1%);
  color: #adb5bd;
}
`;

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  company: Yup.string().required("Company is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
  termsAccepted: Yup.bool().oneOf([true], "You must accept the Terms of Service"),
  privacyAccepted: Yup.bool().oneOf([true], "You must accept the Privacy Policy")
});

type FormData = Yup.InferType<typeof validationSchema>;

const SignUpForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
    const {
      handleSubmit,
      control,
      register,
      formState: { errors, isSubmitting },
    } = useForm<FormData>({
      resolver: yupResolver(validationSchema),
      defaultValues: {
        termsAccepted: false,
        privacyAccepted: false,
      },
    });

    const { user, isAuthenticated, refetchUser } = useUserContext();

    const { cart, totalItems } = useCart();
    const location = useLocation();
    const { finalTotal = 0, originalTotal = 0 } = location.state || {};

    const [hovered, setHovered] = useState(false);

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    
    const [termsAcceptedEnabled, setTermsAcceptedEnabled] = useState(false);
    const [privacyAcceptedEnabled, setPrivacyAcceptedEnabled] = useState(false);

    const onSubmitAuthenticated = async () => {
      try {
        const products = cart.map((item) => ({
          productId: item.category,
          name: item.category,
          description: `Buy ${item.quantity} units of ${item.category}`,
          price: parseFloat((item.price * 1.085).toFixed(2)), // Agregar impuesto del 19%
          currency: "usd",
          quantity: item.quantity,
        }));
        
        const payload: IMarketPaymentCart = { products };
        const paymentResponse = await PaymentService.createPaymentSession(payload);
    
        if (paymentResponse.url) {
          window.location.href = paymentResponse.url;
        }
      } catch (error: any) {
        console.error(error);
        setModalMessage(error.response?.data?.message || "An error occurred. Please try again.");
        setShowErrorModal(true);
      }
    }
    
    const onSubmit = async (data: FormData) => {
      if (totalItems === 0) {
        alert("Your cart is empty.");
        return;
      }
    
      try {
        if (!isAuthenticated) {
          const ipInfo = await getUserIp();
          if (!ipInfo) {
            throw new Error('Could not get your IP address for registration.');
          }

          const { termsAccepted, privacyAccepted, ...filteredData } = data;

          const registrationData: IRegistration = { ...filteredData, address: ipInfo };
    
          await UserService.register(registrationData);
    
          await refetchUser();
        }
    
        const products = cart.map((item) => ({
          productId: item.category,
          name: item.category,
          description: `Buy ${item.quantity} units of ${item.category}`,
          price: parseFloat((item.price * 1.085).toFixed(2)), // Agregar impuesto del 19%
          currency: "usd",
          quantity: item.quantity,
        }));
        
        const payload: IMarketPaymentCart = { products };
        const paymentResponse = await PaymentService.createPaymentSession(payload);
    
        if (paymentResponse.url) {
          window.location.href = paymentResponse.url;
        }
      } catch (error: any) {
        setModalMessage(error);
        setShowErrorModal(true);
      }
    }

    const handleTermsAccepted = () => {
      setTermsAcceptedEnabled(true);
    };
  
    const handlePrivacyAccepted = () => {
      setPrivacyAcceptedEnabled(true);
    };

    const handleCloseModal = () => {
        setShowSuccessModal(false);
        setShowErrorModal(false);
        if (showSuccessModal) {
            //navigate('/');
        }
    };

    if (isAuthenticated) {
      return (
        <Section className="">
          <h3>Welcome back!</h3>
          <p>You are already authenticated. You may proceed to pay.</p>
          <Button
            variant="primary"
            onClick={onSubmitAuthenticated}
            style={{ width: '100%' }}
          >
            Pay {currencyFormat(finalTotal)}
          </Button>
        </Section>
      );
    }

    return(
      <>
      <style>{formIconStyles}</style>
      <div className="text-center mb-1">
        <h3 className="text-body-highlight mb-2">Sign Up</h3>
        <p className="text-body-tertiary">Create your account to pay</p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3 text-start">
          <label htmlFor="name">Name</label>
          <div className="form-icon-container">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  id="name"
                  type="text"
                  placeholder="Name"
                  className="form-icon-input form-control"
                />
              )}
            />
            <FontAwesomeIcon icon={faUser as IconProp} className="form-icon" />
          </div>
          {errors.name && <span className="text-danger">{errors.name.message}</span>}
        </div>

        <div className="mb-3 text-start">
          <label htmlFor="lastName">Last Name</label>
          <div className="form-icon-container">
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  className="form-icon-input form-control"
                />
              )}
            />
            <FontAwesomeIcon icon={faUser as IconProp} className="form-icon" />
          </div>
          {errors.lastName && <span className="text-danger">{errors.lastName.message}</span>}
        </div>

        <div className="mb-3 text-start">
          <label htmlFor="company">Company</label>
          <div className="form-icon-container">
            <Controller
              name="company"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  id="company"
                  type="text"
                  placeholder="Company"
                  className="form-icon-input form-control"
                />
              )}
            />
            <FontAwesomeIcon icon={faBuilding as IconProp} className="form-icon" />
          </div>
          {errors.company && <span className="text-danger">{errors.company.message}</span>}
        </div>

        <div className="mb-3 text-start">
          <label htmlFor="email">Email</label>
          <div className="form-icon-container">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  id="email"
                  type="email"
                  placeholder="name@example.com"
                  className="form-icon-input form-control"
                />
              )}
            />
            <FontAwesomeIcon icon={faEnvelope as IconProp} className="form-icon" />
          </div>
          {errors.email && <span className="text-danger">{errors.email.message}</span>}
        </div>

        <div className="mb-3 text-start">
          <label htmlFor="password">Password</label>
          <div className="form-icon-container">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  id="password"
                  type="password"
                  placeholder="Password"
                  className="form-icon-input form-control"
                />
              )}
            />
            <FontAwesomeIcon icon={faLock as IconProp} className="form-icon" />
          </div>
          {errors.password && <span className="text-danger">{errors.password.message}</span>}
        </div>

        <div className="mb-3 text-start">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <div className="form-icon-container">
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <input
                  {...field}
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm Password"
                  className="form-icon-input form-control"
                />
              )}
            />
            <FontAwesomeIcon icon={faLock as IconProp} className="form-icon" />
          </div>
          {errors.confirmPassword && (
            <span className="text-danger">{errors.confirmPassword.message}</span>
          )}
        </div>

        <div className="mb-3">
          <Form.Check type="checkbox" id="termsAccepted" className="mb-3">
            <Form.Check.Input
              {...register("termsAccepted")}
              type="checkbox"
              disabled={!termsAcceptedEnabled}
              className={errors.termsAccepted ? "is-invalid" : ""}
            />
            <Form.Check.Label>
              I accept the{" "}
              <Link to="#" onClick={(e) => { e.preventDefault(); setShowTermsModal(true) }}>
                Terms of Service
              </Link>
            </Form.Check.Label>
          </Form.Check>
          {errors.termsAccepted && (
            <span className="text-danger">{errors.termsAccepted.message}</span>
          )}
        </div>

        <div className="mb-3">
          <Form.Check type="checkbox" id="privacyAccepted" className="mb-3">
            <Form.Check.Input
              {...register("privacyAccepted")}
              type="checkbox"
              disabled={!privacyAcceptedEnabled}
              className={errors.privacyAccepted ? "is-invalid" : ""}
            />
            <Form.Check.Label>
              I accept the{" "}
              <Link to="#" onClick={(e) => { e.preventDefault(); setShowPrivacyModal(true) }}>
                Privacy Policy
              </Link>
            </Form.Check.Label>
          </Form.Check>
          {errors.privacyAccepted && (
            <span className="text-danger">{errors.privacyAccepted.message}</span>
          )}
        </div>

        <Button
          variant="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={isSubmitting}
          style={{ width: "100%" }}
        >
          {isSubmitting ? "Loading..." : `Sign Up & pay $${finalTotal}`}
        </Button>
      </form>

      <SuccessModal
        show={showSuccessModal}
        onHide={handleCloseModal}
        message={modalMessage}
      />

      <ErrorModal
        show={showErrorModal}
        onHide={handleCloseModal}
        message={modalMessage}
      />
      
      <TermsModal
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
        onExited={handleTermsAccepted}
      />
      <PrivacyModal
        show={showPrivacyModal}
        onHide={() => setShowPrivacyModal(false)}
        onExited={handlePrivacyAccepted}
      />
    </>
    );
};

export default SignUpForm;