import { getHatateToken } from "helpers/utils";
import { useUserContext } from "layouts/UserContext";
import { Navigate } from "react-router-dom";
//import React from "react";

export const ProtectedRoute = ({ children, redirectToSignIn }: any) => {
  if (getHatateToken() == '' && redirectToSignIn) {
    return <Navigate to="/pages/authentication/split/sign-in" replace />;
  }

  const { user } = useUserContext();
  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (!user.verified) {
    alert('Account not verified. Please subscribe to a plan to view this page.');
    return <Navigate to="/" replace />;
  }

  return children;
};
